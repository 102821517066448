<template>
  <div class="t-container">
      <div class="t-yours-truly">
            <h1>Invoice :  <span></span></h1>
            <div class="t-pay-us">
                    <stripe-elements
                    ref="elementsRef"
                    :pk="publishableKey"
                    :amount="amount"
                    locale="en"
                    @token="tokenCreated"
                    @loading="loading = $event"
                    >
                    </stripe-elements>
                    <button @click="submit">Pay ${{amount / 100}}</button>
                </div>
      </div>

  </div>
</template>

<script>
import { StripeElements } from 'vue-stripe-checkout';
export default {
    components: {
        StripeElements
    },
    data: () => ({
        loading: false,
        amount: 1000,
        publishableKey: "pk_test_ziknTntYh937xpzlOgHZNLyx00kIxsw9Sv", 
        token: null,
        charge: null
    }),
    mounted(){
        this.renderWorkAnimationLoadUp();
        this.detectMobileDevice();
        this.amount = this.$route.params.o;
        
        if(this.$route.params.invoice == undefined){
            document.querySelector(".t-yours-truly").innerHTML = "<h1>Something went wrong.</h1>"
        }else {
            document.querySelector("h1 span").innerText = this.$route.params.invoice;
        }
        

    },
    ready: function () {


    },
    methods:{
        detectMobileDevice: function(){
               try{
                   if(document.createEvent("TouchEvent")) {
                       document.querySelector(".total-header-msg").style.paddingTop = "calc(17px*0.9)";
                       document.querySelector(".t-inner-container").style.paddingTop = "0px";
                   }
                } catch(e){ 
                    return false; 
                }
        },
        renderWorkAnimationLoadUp:function() {
            document.documentElement.style.setProperty('--mobilePaint', "#000");
            document.querySelector("body").style.backgroundColor = "grey";
            document.querySelectorAll(".t-section a").forEach((attr)=>attr.style.color="#000")
        },
        submit () {
            this.$refs.elementsRef.submit();
        },
        tokenCreated (token) {
            this.token = token;
            this.charge = {
                source: token.id,
                amount: this.amount,
                description: this.description 
            }
            this.sendTokenToServer(this.charge);
        },
        sendTokenToServer (charge) {
            window.Tilda.post({
                url:"https://www.tildalabs.dev/php/trie.php",
                data:{
                    token: JSON.stringify(charge), 
                    type:"pay", 
                    invoice:this.$route.params.invoice
                },
                success:function(x) {
                    console.log(x);
                    return document.querySelector(".t-yours-truly").innerHTML = "<h1 style='font-size:72px'>Thank you.</h1>";
                },
                reject: function (err) {
                    console.log(err);
                }
            });
    
        }
  
    },
    

}
</script>

<style lang="scss">
@font-face {
  font-family: "LotaGrotesque";
  src: url('./../assets/Lota-Grotesque-Regular.woff');
}
@font-face {
  font-family: "LotaGrotesque-Thin";
  src: url('./../assets/Lota-Grotesque-Thin.woff');
}
:root {
  --paint: #000;
  --paintOff: #fff;
  --mobilePaint: #fff;
}
.t-name{
    color: var(--mobilePaint);
}
.t-pay-us{
    width: 70%;
    margin: 0 auto;
    margin-top:10% ;
    button {
        border: none;
        color: white;
        padding: 10px 32px;
        border-radius: 8px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        background-color: white; 
        color: black; 
        border: 2px solid #2c3e50;
    }

    button:hover {
        background-color: #2c3e50;
        color: white;
    }
}
@media screen and (max-width: 600px) {
    .b-a {
        transition: all .25s;
        content: "";
        position: absolute;
        right: 0;
        height: 2px;
        width: 30px;
        background-color: black !important;
        }
        .menu-collapsed{
            display: block;
            transition: all .25s;
            position: fixed;
            top: 21px;
            right:0px;
            height: 36px;
            width: 36px;
            z-index: 1;
            cursor: pointer;
        .bar {
            background-color:var(--mobilePaint);
            &:before {
                background-color:var(--mobilePaint);
            }
            &:after {
                background-color:var(--mobilePaint);
            }
        }
    }
    .b-a-expanded {
        transition: all .25s;
        top: -0px;
        }
        .menu-expanded {
            transition: all .25s;
            text-align: center;
            height:98vh;
            width: 100%;
            border-radius: 0px;
            top: 0;
            right: 0;
            background-color: var(--paintOff) !important;
        .bar {
            background-color: transparent;
            transition: all .25s;
            margin: 6px;
            &:before {
                @extend .b-a-expanded;
                transform: rotate(45deg);
                background-color:var(--paint);
                z-index: 100;
            }
            &:after {
                @extend .b-a-expanded;
                transform: rotate(-45deg);
                background-color:var(--paint);
                z-index: 100;
            }
        }
        li{
            background-color:var(--paintOff);
            a{
                color: var(--paint);
            }
        }
        .re-open{
            top:21px
        }
    }
}
</style>